actions:
  alerts: Alertas
  new_goal: Nuevo objetivo
  pin_column: Fijar
  quick_actions: Acciones rápidas
  report_alert: Informar
  report_alert_description: >-
    Especifica los detalles de la notificación y selecciona a las personas para
    recibir alertas:
  unpin_column: Desfijar
alerts:
  add_comment: Comentar la alerta o añadir detalles...
  alert_center: Centro de alertas
  assigned_to: asignó la alerta a
  comment: Comentario
  get_more: Cargar + { count } alerta | Cargar + { count } alertas
  information_about_alerts: Más información sobre las alertas...
  justification: Justificación
  load_more: Más alertas
  minimum_one_justification: Debe proporcionar al menos una justificación
  minimum_one_selected_people: Se debe asignar al menos una persona a la alerta
  my_interventions: Mis intervenciones
  new_alert: Nueva alerta
  no_justification_provided: No se ha proporcionado justificación
  no_saved_answers: No hay respuestas guardadas
  people_to_alert: 'Personas o grupos a alertar:'
  raised_by_me: Levantadas por mí
  removed_from: desasignó
  select_users_to_alert: Seleccione la(s) persona(s) a alertar
  status_history: Historial del estado de la alerta
  to_signal: 'A señalar:'
analysis:
  analysis: Análisis
application_fields:
  all_operations: Todas las operaciones
  all_operations_search: 'Todas las operaciones que contienen: { entry }'
  all_products: Todos los productos
  all_products_search: 'Todos los productos que contienen: { entry }'
  all_workplaces: Todos los lugares de trabajo
  all_workplaces_search: 'Todos los lugares de trabajo que contienen: { entry }'
  application_fields: Campos de aplicación
  charging-station: Estación de carga
  operations: Operaciones
  products: Productos
  product: Producto
  reference: Referencias
  select_all_operations: Seleccionar todas las operaciones
  select_all_products: Seleccionar todos los productos
  select_all_workplaces: Seleccionar todos los lugares de trabajo
  select_operation: Seleccionar una operación...
  select_product: Seleccionar un producto...
  select_workplace: Seleccionar un puesto de carga...
  workplaces: Lugares de trabajo
authentication:
  wrong_authentication_method: >-
    su usuario no tiene el permiso para conectarse usando una contraseña,
    consulte a su administrador para obtener más información.
batch_record:
  batch_record_created: El documento ha sido creado y adjunto a las órdenes de producción seleccionadas.
  batch_record_creation_error: Se produjo un error al crear el documento.
  add_comment: Añadir un comentario
  batch: Lote
  batch_sumup: Ver el resumen del Lote
  delete_document_from_batch_description: >-
    ¿Estás seguro de que deseas eliminar este adjunto? Esta acción es irreversible.
  delete_document_from_batch_title: Eliminar un adjunto
  documents: Fichas del Lote
  information: Información del Lote
  last_released_by_title: Última liberación del Lote
  modal_release_description: >-
    Estás a punto de liberar el Lote. Asegúrate de la conformidad total del Lote antes de validar. Por favor, ingresa tus credenciales para confirmar.
  modal_release_title: ¿Estás seguro de que deseas liberar el Lote { name }?
  new_document: Añadir un adjunto
  release: Liberar
  release_batch_error: Credenciales incorrectas.
  release_title: Liberación del Lote
  release_title_sidebar: Liberación de lotes
  released_batch: Lote liberado el { date } por
  update_document: Modificar la ficha
bread_crumb:
  documents: Todos los documentos
  new_view: Nueva vista
  reports: Todos los informes
  simulation: Simular una orden de producción
  templates: Todos los plantillas
  views: Todas las vistas
button:
  about: Acerca de
  back: Regresar
  go: IR
  home: Inicio
  toggle_dark: Alternar modo oscuro
  toggle_langs: Cambiar idioma
dashboard:
  empty_dashboard: No hay ningún panel disponible.
  last_30_days: Los últimos 30 días.
  select: Seleccione un panel
  select_dashboard_placeholder: Seleccione
dates:
  day: hace un día | hace {amount} días
  hour: hace una hora | hace {amount} horas
  minute: hace un minuto | hace {amount} minutos
  month: hace un mes | hace {amount} meses
  second: ' hace un segundo | hace {amount} segundos'
days:
  daily: Todos los días
  friday: Viernes
  monday: Lunes
  saturday: Sábado
  sunday: Domingo
  thursday: Jueves
  tuesday: Martes
  wednesday: Miércoles
display:
  all_columns: Todas las columnas
  editable_columns: Columnas editables
document:
  add_new_block: Agregar un nuevo bloque
  application_field: Campo de aplicación
  archive: Archivar el documento
  starts_with_report_launch: El momento del lanzamiento de la operación
  archive_confirmation_message: ¿Está seguro de que desea archivar el documento?
  ask_admin: Por favor, póngase en contacto con el administrador de la aplicación
  cant_finish_document: Todavía faltan entradas por completar
  cant_finish_document_description: >-
    No puedes finalizar este documento sin completar las celdas obligatorias (no
    opcionales). Sin embargo, puedes ingresar "N/A" en las celdas pertinentes o
    informarlas.
  cant_finish_document_description_mandatory_lines: 'Las siguientes líneas son obligatorias:'
  capture: Captura
  category: Categoría
  cells_missing: '{number} celda(s) incompleta(s)'
  column_number: Número de columna
  columns: Columna
  status_being_processed: Siendo procesados
  confirm_archive_in_document:
    description: >-
      Está a punto de archivar este documento. Una vez archivado, este documento
      ya no estará disponible para su uso en el OF. ¿Está seguro de que desea
      continuar?
    title: Confirmar archivo
  confirm_archive_many_documents:
    description: >-
      Está a punto de archivar 1 documento. Este documento ya no podrá
      utilizarse en los OF. | Está a punto de archivar {count} documentos. Estos
      documentos ya no podrán utilizarse en los OF.
    title: Confirmar archivo
  connect_to_balance: Conéctate al aparato
  create: Nuevo documento
  create_new_document: ¿Confirmar el guardado?
  create_new_document_message: Está a punto de crear la primera versión de este nuevo documento.
  create_new_version: ¿Crear una nueva versión?
  create_new_version_message: Está a punto de cambiar de versión al guardar este documento
  created_by: Creado por
  created_on: Creado el
  cycle_of_document: Ciclo del documento
  delete: Eliminar el documento
  delete_confirmation_message: >-
    ¿Está seguro de que desea eliminar el documento? Esta acción es
    irreversible.
  display: Visualización
  document_archive_message: El documento ha sido archivado con éxito
  document_archive_title: Documento archivado
  document_creation_message: El documento ha sido creado con éxito
  document_delete_message: El documento ha sido eliminado con éxito
  document_to_model_success: Documento convertido con éxito en modelo
  document_update_error_message: Se produjo un error al guardar el documento
  document_duplicate_error_message: Se ha producido un error al duplicar el documento
  document_update_message: El documento se ha guardado con éxito
  enter: Ingresar
  exit_without_saving: Salir sin guardar
  expiration_date: Fecha de vencimiento
  file: Enlace
  file_extension_error: La extensión no está permitida
  finish: Finalizar la edición del documento
  finish_confirmation_message: ¿Está seguro de que ha terminado de completar este formulario?
  format: Formato
  frequency: Frecuencia
  get_more: Cargar + { count } documento | Cargar + { count } documentos
  get_more_revisions: Cargar + { count } revisión | Cargar + { count } revisiones
  input_frequency: Frecuencia de entrada
  input_frequency_activate: Activar frecuencia de entrada
  last_updated_on: Última modificación el
  lifecycle: Ciclo de vida del documento
  load_more: Más documentos
  load_more_revisions: Más revisiones
  measures: Medida
  multiple_selections: Selección múltiple
  name: Nombre
  new_document: Nuevo documento
  no_files: No hay archivos disponibles
  no_workplace_documents: No hay archivos disponibles para esta estación de trabajo
  non_valid: no conforme
  not_found: No se encontraron registros
  note: Nota
  notify_operator: Notificar al operador
  number_of_columns: Número de columnas
  order: Orden
  production_order: Orden de producción
  publish_document: Publicar el documento
  schedule:
    as_soon_as: Tan pronto como
    as_soon_as_the_publication: Tan pronto como se publique
    available_from: Disponible desde
    availablity: Disponibilidad
    day: día | días
    document_is_accessible: El documento será accesible
    end: Fin
    end_at: Termina el
    every_day: Cada {nb} días
    every_f: Toda | Todas
    every_m: Todos los
    every_month: Cada {nb} meses
    every_week: Cada {nb} semanas
    every_week_article: Cada
    every_week_days: Cada {days}
    every_with_article: Todos los
    every_year: Cada {nb} años
    from_date: desde el {date}
    hour: hora | horas
    minute: minuto | minutos
    month: Mes
    no_end: Sin fecha de finalización
    no_schedule: Sin repetición
    no_start: Sin fecha de inicio
    no_starting_date: Sin fecha de inicio
    not_recognized: Recurrencia no reconocida
    permanently_available: Disponible permanentemente
    publication_date: Fecha de publicación
    recurrence: Recurrencia
    recurrence_from: Desde
    recurrence_to: a
    repeat: Repeticiones
    repeat_each: Repetir cada
    repeat_each_feminine: Repetir cada
    set_hour: Establecer hora
    set_hour_error: La hora de finalización debe ser mayor que la de inicio.
    specific_recurrence: Recurrencia específica
    start: Inicio
    start_at: Inicia el
    the_f: la | las
    the_m: el | los
    until_date: hasta el {date}
    week: Semana | Semanas
    year: Año | Años
  scheduling: Programación
  scheduling_subtitle: Planifique la visualización del documento en el espacio de operaciones
  search: Buscar un documento
  select_application_fields: Seleccionar campos de aplicación
  simulate: Simular
  start_date: Fecha de inicio
  start_to_create_a_new_document: Comenzar a crear un nuevo documento
  starting_at: Comienza en
  starting_with: Empieza con
  state: Estado
  status: Estado
  status_active: Activo
  status_archived: Archivado
  status_deleted: Eliminado
  status_draft: Borrador
  status_finished: Finalizado
  status_in_progress: En progreso
  status_in_review: En revisión
  status_inactive: Inactivo
  status_lot_libéré: Lote liberado
  status_new: Nuevo
  status_production_validée: Producción validada
  status_published: Publicado
  status_qualified: Calificado
  status_revu_quali_en_cours: Revista de calidad en curso
  status_select: Seleccionar
  status_released: Liberado
  status_resolved: Resuelto
  step_check: OK/NOK
  step_checkbox: Casilla de verificación
  step_file: Archivo
  step_link: Enlace
  step_measure: Medida
  step_number: Número
  step_calculator: Calculadora
  step_options_list: Lista de opciones
  step_photo: Foto
  step_photo_no_camera_access: >-
    El acceso a la cámara está desactivado. Autorice el acceso en la
    configuración de su navegador.
  step_photo_no_devices_found: No se encontraron dispositivos. Conecte una cámara USB, por favor.
  step_photo_preview_picture: Vista previa de la foto
  step_photo_take_picture: Tomar una foto
  step_photo_add_picture: Añadir una foto
  step_photo_take_picture_again: Tomar la foto de nuevo
  step_photo_take_picture_description: >-
    Utilice su cámara para tomar una foto. Haga clic en "Tomar" para guardar la
    foto y prévisualícela durante 2 segundos antes de que se cierre
    automáticamente la ventana modal.
  step_photo_update_picture: Actualizar la foto
  step_photo_update_picture_description: >-
    Puede actualizar su foto existente utilizando la cámara. Haga clic en "Tomar
    una foto" para guardar la nueva foto; se mostrará durante 2 segundos y la
    ventana emergente se cerrará automáticamente, reemplazando la foto anterior.
  step_text: Texto
  step_time: Hora
  steps_linked_to_template: Algunos bloques ya están vinculados a un modelo
  title: Mis documentos
  to_fill: Por completar
  trigger: Desencadenante
  trigger_element: Elemento desencadenante
  unlink_steps: Desvincule primero los bloques que ya han sido importados de un modelo.
  unsaved_changes_description: Cualquier modificación se perderá si no guarda este documento
  unsaved_changes_title: Este documento no está guardado
  update_research: Modificar mi búsqueda
  updated_at: Actualizado el
  updated_by: Actualizado por
  updated_on: Actualizado el
  use_as_template: Usar como modelo
  valid: es conforme
  version: Versión
  version_reason_add: Agregar una nota sobre la nueva versión del documento
  version_reason_error: Debe justificar la nueva versión del documento.
  workflow: Ciclo de vida
  you_have_completed: Ha completado
error:
  email_already_in_use: El correo electrónico ya está en uso
  nfc_code_policy_not_valid: El código debe tener 16 caracteres.
  not_same_old_password: La contraseña no puede ser igual a la antigua
  password_policy_not_valid: >-
    La contraseña debe tener al menos 8 caracteres y contener letras minúsculas
    y mayúsculas
  passwords_not_match: Las contraseñas no coinciden
export:
  at: en
  attachments_of_document: Archivos_adjuntos_del_documento
  change_target: Cambio de objetivo
  columns_count: Número de columnas
  description: Descripción
  captured_data: Datos capturados entre
  document_export_error: Se produjo un error al preparar su documento.
  download_error: Se produjo un error al conectar al servidor.
  download_is_starting: Su descarga comenzará en breve...
  export_document_as_pdf: Exportar el documento como PDF
  export_type: Tipo de exportación
  goal: Objetivo
  historic_value: Valor histórico
  justification: Justificación
  max: Máximo
  min: Mínimo
  modified_at: Modificado el
  modified_by: Modificado por
  name: Nombre
  operator: Operador
  report_title: Título del informe
  status: Estado
  step_type: Tipo de paso
  tags: Etiquetas
  tool: Herramienta de medición
  type: Tipo
  unit: Unidad
  update_date: Fecha de actualización
  value: Valor
filters:
  activities: Actividades
  add: Agregar filtros
  alternative_workplaces: alternative workplaces
  assignation: Asignación
  assignee: Asignado a
  model: Formulario de incidente
  incident_form: Formulario de incidente
  at_least_one_display_column: Debe seleccionar al menos una opción
  blocker: Bloqueante
  blockers: Bloqueantes
  categories: Categoría del documento
  choose_filter: Elegir un filtro
  date: Fecha
  display: Mostrar
  display_columns: Columnas mostradas...
  documents: Documentos
  filter: Filtrar
  filter_by: Filtrar por
  go_back: Volver a las opciones de filtros
  group_by: Agrupar por
  last_14_days: Últimos 14 días
  last_24_hours: Últimas 24 horas
  last_3_days: Últimos 3 días
  last_7_days: Últimos 7 días
  last_month: Último mes
  machines: Estaciones de carga
  models: Plantillas
  no_grouping: Ninguno
  no_product: Sin producto
  no_workplace: Sin puesto de trabajo
  operation: Operación
  operations: Operaciones
  parent: Padres
  product: Producto
  production_orders: Órdenes de producción
  products: Producto
  raised_by: Generado por
  remove_all: Eliminar todo
  role: Rol de usuario
  search: Buscar un filtro...
  select_a_date: Seleccionar una fecha
  status: Estado
  teams: Equipos
  templates: Plantillas
  title: Filtros
  today: Hoy
  users: Operadores
  work_order: Orden de trabajo
  workplace: Puesto de trabajo
  workplaces: Puestos de trabajo
  yesterday: Ayer
firebase:
  too_many_attempts: >-
    Tu cuenta está temporalmente desactivada debido a demasiados intentos. Por
    favor, restablece tu contraseña
  unexpected_error: Se produjo un error inesperado. Por favor, inténtalo de nuevo más tarde
  user_disabled: Tu cuenta ha sido desactivada
  weak_password: La contraseña debe tener al menos 6 caracteres
  wrong_actual_password: Contraseña actual incorrecta
  wrong_email: Correo electrónico incorrecto
  wrong_password: Contraseña incorrecta
format:
  fixed: Fijo
  formula: Fórmula
  quantity: Cantidad
  unique: Único
  unlimited: Ilimitado
global:
  parent: Padres
  updated_by: Actualizado por
  modify: Modificar
  display_NC_only: Mostrar sólo las columnas no conformes
  step: Paso
  steps: Pasos
  cell: celúla
  history_annex: anexo historico
  pictures_annex: Apéndice de bloques de imágenes
  column: Columna | Columnas
  account_and_access: Usuarios
  document_attachment_annex: Anexo de los documentos adjuntos al informe
  step_attachment_annex: Anexo de documentos adjuntos a las etapas
  actions: Acciones
  add: Agregar
  add_a_attachment_file: Agregar un archivo adjunto
  add_user: Agregar un usuario
  additional_informations: Información adicional
  alert: Alerta | Alertas
  alerts: Alertas
  alerts_success: ¡Alerta creada con éxito!
  all: Todo
  allTeams: Todos los equipos
  all_the_documents: Todos los documentos
  all_the_of: Todos los OF
  all_the_templates: Todas las plantillas
  analyses: Análisis
  and: 'y'
  application_fields: Campos de aplicación
  archive: Archivar
  are_you_sure_you_want_to_delete_user: ¿Está seguro de que desea eliminar a este usuario?
  are_you_sure_you_want_to_logout: ¿Está seguro de que desea cerrar la sesión?
  at: en
  attachment: Archivo adjunto
  attachments: Archivos adjuntos
  attention_any_unsaved_document_will_be_lost: Atención, cualquier documento no guardado se perderá
  audit_logs: Registros de auditoría
  back_home: Volver a la página de inicio
  beforeOrAfter: Antes o después
  both_passwords_dont_match: Ambas contraseñas no coinciden
  bread_crumb:
    documentos: documents
    plantillas: templates
    simulacion: simulation
  cancel: Cancelar
  change_language_to_apply: Se recomienda encarecidamente actualizar la página para aplicar los cambios
  change_password: Cambiar contraseña
  choice: elección
  click_button_reload: Haga clic en "Recargar" para acceder a ella.
  client: Cliente
  clients: Clientes
  close: Cerrar
  complete: Completar
  confirm: Confirmar
  next: próxima
  confirm_import: Confirmar la importación de datos
  confirm_import_desc: >-
    La importación de este archivo actualizará los datos de clientes previamente
    importados en JUNO
  confirm_modification: ¿Confirmar las modificaciones?
  confirm_password: Confirmar contraseña
  confirm_password_validation_label: Ambas contraseñas
  confirmation_password: Confirmación de contraseña
  content: Contenido
  copy_of: Copia de
  create: Crear
  created_at: Creado el
  created_by: Creado por
  created_on: Creado el
  current_language: es
  current_password: Contraseña actual
  dashboard: Tablero de control
  days: días
  define_as_a_template: Definir como plantilla
  delete: Eliminar
  description: Descripción
  disconnected: Ha sido desconectado
  disconnected_for_inactivity: Debido a una inactividad prolongada, ha sido desconectado
  display: Mostrar
  document: documento | documento | documentos
  document_parameters: Parámetros del documento
  documents: Documentos
  download: Descargar
  download_as_pdf: Descargar como PDF
  duplicate: Duplicar
  duplicate_on_destination_site: Sitio de destino
  duplicate_no_language_selected: Ningún idioma seleccionado
  duplicate_no_site_selected: Ningún sitio seleccionado
  duplicate_and_translate: Traducir el documento
  duplicate_on_another_site: Duplicar en otro sitio
  duplicate_on_another_site_successful: El documento se ha duplicado con éxito en el sitio de destino
  edit: Editar
  element: Ningún elemento | 1 elemento | {n} elementos
  element_not_found: Elemento no encontrado
  email: Correo electrónico
  empty_list: No se encontraron registros
  enriched: Enriquecido
  enriched_export: Exportación enriquecida
  error: Error
  export: Exportar
  files: Archivos del puesto
  filter: Filtro | Filtros
  finish_document: Finalizar el documento
  finish_entry: Finalizar mi entrada
  finished: Finalizado
  first_name: Nombre
  for: Para
  forbidden: Acceso prohibido a esta página
  forbidden_message: Póngase en contacto con su administrador para verificar el acceso
  force_reset_password: Está iniciando sesión por primera vez. Por favor, restablezca su contraseña.
  from: desde
  ftp_parameters: Parámetros de FTP
  group: grupo | grupos
  hello: Hola
  history: Historial
  id: Id
  if: Si
  import: Importar
  import_export_parameters: Importar/Exportar
  inProgress: En progreso
  released: Liberado
  integration_parameters: Integración
  integrations: Integraciones
  justification: Justificación
  label: Etiqueta
  last_modification: Última modificación
  last_name: Apellido
  library: Biblioteca
  link: Enlace
  list: Lista
  loading: Cargando...
  login: Iniciar sesión
  logout: Cerrar sesión
  mandatory: Obligatorio
  mark_as_read: Marcar todo como leído
  media_library: Biblioteca de medios
  minutes: minutos
  modeles: plantillas
  monitoring: Supervisión
  month: Mes
  my_dashboards: Mis tableros de control
  name: Nombre
  name_document: Nombre del documento
  nb_of_boxes: Número de cajas
  need_reset_password: ¿Necesita restablecer su contraseña?
  network_error: Sin conexión a Internet
  new: Nuevo
  new_password: Nueva contraseña
  new_version: Hay una nueva versión de Juno disponible
  next_check_at: Próxima verificación en el informe {reportName}, en el paso {step}
  'no': 'No'
  no_value: Sin valor
  notification_parameters: Parámetros de notificación
  number_of_steps: '{number} paso | {number} pasos'
  ok: OK
  operation: Operación
  operator_interface: Interfaz del operador
  optional: Opcional
  or: O
  other: Otro
  out_of: de
  parameters: Parámetros
  password: Contraseña
  password_has_changed: Su contraseña ha sido cambiada
  password_update_error_message: Se produjo un error al cambiar la contraseña
  people: '{amount} personas'
  percentage: porcentaje de tolerancia
  performances: Rendimiento
  permissions: Roles y permisos
  picture: Imagen
  please_select_type: Seleccione un tipo, por favor
  preview: Vista previa
  product: Producto
  publish: Publicar
  range: rango
  reason: Razón
  refresh: Refrescar
  reload: Recargar
  remove_attachment: Eliminar archivo adjunto
  report: Informe | Informes
  reports: Informes
  reset_email_sent: >-
    Se ha enviado un correo electrónico de restablecimiento de contraseña. Haga
    clic en el enlace cuando lo reciba
  resolved: Resuelto
  restore: Restaurar
  revision: Revisión
  role: Rol
  roles: Roles
  row: Fila | Filas
  save: Guardar
  save_preferences: Guardar preferencias
  search: Buscar
  search_assignee: Asignar a
  search_means_of_measurement: Buscar...
  search_unit: Buscar una unidad...
  seeAll: Ver todo
  seeDescription: Ver descripción
  select: Seleccionar
  selectTeam: Seleccione un equipo
  select_tab: Seleccione una pestaña
  select_type: Seleccionar un tipo
  selected_documents: '{n} ficha seleccionada | {n} fichas seleccionadas'
  selected_documents_from_to: Fichas {from}-{to} de un total de {total} fichas
  selected_lookups_from_to: Datos de tipo {from}-{to} de un total de {total} datos
  selected_products_from_to: Productos {from}-{to} de un total de {total} productos
  selected_workplaces_from_to: Puestos de trabajo {from}-{to} de un total de {total} puestos de trabajo
  selected_production_orders_from_to: '{from}-{to} de un total de {total} órdenes de fabricación'
  selected_production_orders: '{n} informe seleccionado | {n} informes seleccionados'
  send: Enviar
  send_reset_email: Enviar correo electrónico de restablecimiento
  settings: Configuración
  sign_out: Cerrar sesión
  simple_export: Exportación simple
  simulation: Simulación
  site_management: Gestión de sitios
  sites: Sitios
  complementary_sites: Sitios complementarios
  start: Comenzar
  startTask: Comenzar una tarea
  status_management: Gestión de estados
  steps_parameters: Parámetros de los pasos
  success: Éxito
  summary: Resumen
  switch_to_operator: Espacio de producción
  switch_to_supervisor: Espacio de supervisión
  switch_user: Cambiar de usuario
  tags: Etiqueta
  template: Plantilla
  template_messages_parameters: Parámetros de mensajes de plantilla
  templates: Plantillas
  then: Entonces
  time: Tiempo
  title: Título
  to_consult: Para consultar
  today: Hoy
  total_of_controles: Total de controles
  total_parts: Total de piezas
  total_report: Informes totales
  understood: Entendido
  unknown: Desconocido
  unlink_step: ¿Está seguro de que desea desvincular este bloque de su plantilla?
  unlink_step_message: >-
    Editar este bloque implica que no se podrá actualizar si rompe el vínculo
    con la siguiente plantilla:
  update: Actualizar
  user: Usuario
  user_session_management: Gestión de la sesión de usuario
  users: Usuarios
  validate: Validar
  view: Ver
  warning: Advertencia
  workflow_parameters: Parámetros del ciclo de vida
  workflows: Flujos de documentos
  workplace: Puesto de trabajo
  'yes': Sí
  you_are_offline: Actualmente está sin conexión. Por favor, vuelva a conectarse.
  your_profile: Su perfil
  ask_site_admin: Póngase en contacto con el administrador de este sitio
  no_rights: No tienes derechos
global_fields:
  created_by: Creado por
  created_on: Creado el
groups:
  created_at: Fecha de creación
  load_more_groups: Más grupos
  name: Nombre
  no_result: No se encontraron resultados.
  placeholder: Selecciona personas o grupos a alertar
  users: Usuarios
history:
  and_more: y {amount} más...
  assigned: asignó la alerta a
  commented: comentó
  created: reportó la alerta
  history: Historial
  updated: cambió el estado de la alerta
  started: la solicitud de asistencia es en el estado
insights:
  valid_blocks: Bloques válidos
  valid_control_points: Puntos de control válidos
  valid_controls: Controles válidos
instructions:
  incidents: Solicitudes de soporte
  archive: Archivar
  create_error: Se ha producido un error
  create_error_msg: Se ha producido un error. Por favor, inténtelo de nuevo más tarde
  created_successfully: Instrucción(es) creada(s) con éxito
  group_and_export: Agrupar y exportar
  instructions_transfer: Traspaso de instrucciones
  modify_instructions: Modificar
  new_instruction: Nueva instrucción
  no_instructions_found: No se encontraron instrucciones
  save_instruction: Guardar
  selected: seleccionado | seleccionados
  unarchive: Desarchivar
  update_error: Se ha producido un error
  update_error_msg: Se ha producido un error. Por favor, inténtelo de nuevo más tarde
  updated_successfully: Instrucción(es) modificada(s) con éxito
  write_instructions: Escriba sus instrucciones para el próximo operador...
intro:
  aka: También conocido como
  desc: Modelo de inicio de Vite Opinionated
  dynamic-route: Demo de ruta dinámica
  hi: "Hola, {nombre}\_!"
  whats-your-name: ¿Cuál es tu nombre?
jobs:
  choose_workplace: Elija su lugar de trabajo
  choose_workplace_desc: Seleccione su lugar de trabajo para mostrar las órdenes de producción
  create_production_order: Crear esta orden de producción
  current_jobs: Panel de control
  last_10_days: Órdenes de producción de los últimos 10 días
  last_30_days: Órdenes de producción de los últimos 30 días
  no-result: No hay resultados para esta búsqueda
  no_product_order_selected: >-
    No se encontró ninguna orden de producción. Haga clic para elegir un
    producto
  not_found: No se encontró ninguna orden de producción abierta
  number: Número de orden de producción
  operation: Número de operación
  production_order_doesnt_exist: ¡Esta orden de producción no existe!
  select-of: Por favor, seleccione una orden de producción
  select_product: Por favor, seleccione un producto
  start_new: Iniciar una orden de producción
  start_new_job_desc_no_jobs: >-
    Abra una orden de producción haciendo clic en el botón en la parte superior
    derecha de la pantalla
  status_finished: Terminado
  status_finished_message: La orden de producción ha sido finalizada
  status_in_progress: En progreso
  status_new: Nueva
languages:
  de: Alemán
  en: Inglés
  english: Inglés
  es: Español
  fr: Francés
  french: Francés
  german: Alemán
  it: Italiano
  italian: Italiano
  spanish: Español
incidents:
  resolved_automatically: esta incidencia se resolvió automáticamente mediante el formulario.
  raised_incident: planteó una nueva solicitud de soporte.
  collected_answers: Respuestas recopiladas
  unsaved_changes_title: Esta fórmula no se guarda.
  unsaved_changes_description: Cualquier cambio se perderá si no guarda este formulario.
  add_question: nombre del paso...
  add_answer: Nombre de la opción de respuesta...
  help_request: Solicitud de ayuda
  add_comment: Comenta o especifica más detalles...
  add_comment_on_incident: Por favor, añada un comentario para ayudarnos a entender con más detalle...
  incidents_form: Formulario de incidente
  last_question: ¿Este formulario le ayudó a resolver su problema?
  help_form_sent_title: Enviado correctamente
  help_form_sent_content: Su solicitud de soporte ha sido enviada exitosamente.
  no_model_available: No hay modelos de soporte disponibles en este momento.
  modify_data_error: Algunos datos requeridos no se completaron correctamente.
  modify_data_success: El nuevo formulario se guardó correctamente..
  missing_response: Falta de respuesta
media_library:
  add: Importar archivo
  archive_confirmation_message: ¿Estás seguro de que deseas archivar este archivo?
  delete: Archivar archivo
  delete_confirmation_message: ¿Estás seguro de que deseas eliminar este archivo?
  failed_upload: Algunos archivos no se pudieron cargar debido a un error
  file_update_error_message: Se produjo un error al conectarse al servidor, inténtelo de nuevo.
  media_parameters: Biblioteca multimedia
  no_data: >-
    No se ha importado ningún archivo, haga clic en el botón de importar para
    agregar sus archivos
  no_file_yet: Agregue sus archivos aquí para importarlos a JUNO
  no_search_result: No se encontró ningún archivo que coincida con su búsqueda
  no_archived_files: No hay ficheros archivados
  search_file: Buscar en su computadora
  show: Mostrar
  staged_file: archivo seleccionado
  staged_files: '1 archivo seleccionado | %{count} archivos seleccionados '
  tab_archived: Archivados
  tab_files: Archivos disponibles
  update_file: Reemplazar archivo
  upload: Importar
  upload_files: Importar archivos
  uploaded_files: '1 archivo importado con éxito | %{count} archivos importados con éxito '
mes:
  charts:
    synthetic_rate_of_return: Tasa de rendimiento sintético
    availability_rate: Tasa de disponibilidad
    quality_rate: Tasa de calidad
    performance_rate: Tasa de rendimiento
    percentage_of_use: '% de uso'
    stop_pareto: Pareto de paradas
    scrap_pareto: Pareto de desechos
    slowdown_pareto: Pareto de ralentizaciones
  my_performance: Mi rendimiento
  scrap: rechazo | rechazos
  signal_scrap: Informar de un rechazo
  scrap_quantity: Cantidad rechazada
  show_nc_only: Solo la no conformidad
  no-nc-today: No hay no conformidad hoy
  no-nc-in-selected-date: No hay no conformidad en el día seleccionado.
  production_rate_expected: Tasa esperada
  add_production_rate_expected: Agregar una tasa esperada
  machine_status_between: El estado de la estación de carga entre %{start} y %{end}
  number_of_pieces: Número de piezas
  pieces: piezas
  associate-po-to-my-machine:
    description: >-
      Por favor, ingrese la información requerida para asociar una OP a su
      estación de carga y ver la cantidad esperada a producir.
    production-order-number-label: Número de OP
    production-order-number-placeholder: Ingrese el número de OP...
    quantity-label: Cantidad a Producir
    quantity-placeholder: Ingrese la cantidad a producir...
    save: Asociar OP
    title: Asociar una OP a mi Estación de carga
  handle_production:
    start_on_current_session: Iniciar la producción en la estación de carga
    confirm_current_production: Confirmar que la orden actual está en producción en la estación de carga
    confirm_finished_production: Confirmar que la orden actual ya no está en producción en la estación de carga
    unlink_env: 'Su producción será desvinculada del siguiente entorno de trabajo:'
    link_env: 'Su producción será vinculada al siguiente entorno de trabajo:'
    relink_env: 'y será readjuntada a este entorno de trabajo:'
    start: Comenzar mi producción
    in_progress: Producción en curso
    stop: Detener mi producción
  machines: Estaciones de carga
  compared_to: En comparación con
  date: Fecha
  available: Disponible
  batch: Lote
  digram-trs: Diagrama en cascada TRS
  machine: Estación de carga
  machine-has-no-data: La estación de carga no tiene datos
  machines-indicators: Indicadores por estación de carga
  net-production-time: Tiempo neto de producción
  no-machines-found: No se encontraron estaciones de carga
  opened-time: Tiempo abierto
  paretor-unknown-stoppage: Paradas imprevistas
  performances: Actuaciones
  oee: OEE
  availability: Disponibilidad
  performance: Rendimiento
  quality: Calidad
  po: OP
  production: Producción
  production-indicators: Indicadores de producción
  production-monitoring: Control de la producción
  production-rate: Tasa de producción
  production-rate-delta: Tasa de producción
  production-time: Tiempo de producción
  quality-waste: Calidad y residuos
  production_target_line_chart: Objetivo de producción
  setting: Configuración
  setting-shutdown-reasons: Configuración de razones de apagado
  all-machines: Todas las estaciones de carga
  settings:
    modal:
      color: Color de la configuración
      edit:
        title: Editar razón de apagado
      machine: Estación de carga
      new:
        title: Nueva razón de apagado
      reason: Título de la razón de apagado
      reason-placeholder: Ingrese un título...
      stop-machine-reason: Seleccione una estación de carga
    table:
      color: Color
      machine: Estación de carga
      status: Estado
      title: Título
      produced-parts: Piezas producidas
      oee: OEE (%)
      availability: Disponibilidad (%)
      performance: Rendimiento (%)
      quality: Calidad (%)
  total-time: Tiempo total
  unknown-stoppage: Parada imprevista
  no-available-data: No hay datos disponibles
  full-screen: Pantalla completa
  exit-full-screen-mode: Salir del modo de pantalla completa
monthes:
  april: Abril
  august: Agosto
  december: Diciembre
  february: Febrero
  january: Enero
  july: Julio
  june: Junio
  march: Marzo
  may: Mayo
  november: Noviembre
  october: Octubre
  september: Septiembre
not-found: No encontrado
notifications:
  activate_feature: Activar o desactivar
  alert_type: Notificaciones de alerta
  controls_needed: control que necesita realizarse | controles que necesitan realizarse
  go_to_task: Ir a la tarea
  machine_stopped: >-
    Estación de carga detenida a %{time}. Por favor, especifique inmediatamente la razón
    de esta detención
  qualify_stop: Calificar la parada
  reminder_type: Notificaciones de recordatorio
  undefined_document: Documento sin nombre
  view: Ver notificación
operator:
  all_workplaces: Todos los puestos de trabajo
  filter_by_workplaces: Filtrar por puesto de trabajo
  no_workplaces_recently_opened: Aún no has abierto ningún puesto de trabajo
  qualify:
    modal:
      comment: Comentario
      comment-placeholder: Agregar un comentario...
      description: >-
        Se ha detectado una parada de producción en la estación de carga %{machine} a las
        %{time}. Por favor, proporcione los siguientes detalles para calificar
        el evento de parada.
      optional: Opcional
      reason: Razón de la Parada
      reason-placeholder: Seleccionar una razón de parada...
      title: Calificar Paro de Estación de carga
  requalify:
    modal:
      description: >-
        Por favor, proporcione la siguiente información para reclasificar este
        evento de parada.
      reason: Razón de Reclasificación
      reason-placeholder: Seleccione una razón de reclasificación...
      title: Reclasificar Parada de Estación de carga
  search_workplace: Buscar puesto de trabajo
  search_workplace_message: Refina tu búsqueda para mostrar los puestos de trabajo asociados
  sel: Buscar puesto de trabajo
  select_all_workplaces: Seleccionar todos los puestos de trabajo
  sidebar:
    machine-is-available-again: Estación de carga Disponible Nuevamente
    machine-is-off: Estación de carga Apagada
    machine-monitoring: Seguimiento de Estaciones de carga
    nir: Vigilancia NIR
    qualify: Calificar
    requalify: Requalificar
    workplace-documents: Fichas de Puesto
  sidebar_nir_monitoring: Monitoreo NIR
  sidebar_operations_support: Soporte operativo
  sidebar_workplace_documents: Descripciones de trabajo
  workplace: Puesto de trabajo
  workplace_doesnt_exist: Este puesto de trabajo no existe
  workplaces_recently_opened: Puestos de trabajo abiertos recientemente
pages:
  alerts: Todas las alertas
  alerts_list: Todas las alertas
  analytics: Análisis
  document: Documentos
  form: Formularios
  history: Historial
  library: Biblioteca
  media_library: Biblioteca multimedia
  monitoring: Supervisión
  reports: Todos los informes
  settings: Configuración
  template: Plantillas
  views: Vistas
profil:
  description: Administra toda la información personal relacionada con tu perfil de JUNO
  email: Correo electrónico
  language: Idioma
  my-profile: Mi perfil
  not_specified: No especificado
  security: Seguridad
  trigram: Identificación de la planta
repetition:
  add: Crear repetición
  additional_filter_nature: Naturaleza
  create_formula: Crear fórmula
  create_sampling: Crear muestreo
  created_successfully: La repetición se ha creado con éxito
  created_successfully_message: Ahora puedes comenzar a usar la nueva repetición en tus documentos
  creation_error: Se produjo un error inesperado
  create_frequency: Crear una frecuencia
  frequency_list: Lista de frecuencias
  creation_error_message: Se produjo un error inesperado al crear tu repetición.
  event: Evento
  formula: Fórmula
  formula_type: Indicar tipo de fórmula
  frequency: Frecuencia
  frequency_selection_validation_error: Debes seleccionar al menos una vez.
  load_more: Cargar más
  no_repetition_available: No hay repetición disponible
  notification: Notificación
  repeat_every: Repetir cada
  repetition_exists_message: Esta repetición ya existe
  repetitions: Repeticiones
  reset: Restablecer
  sampling: Muestreo
  save: Guardar
  search: Buscar
  select: Seleccionar repetición
  select_formula: Seleccionar fórmula
  times: Veces
report:
  double_check_toast: Otro operador debe realizar el control
  checking_data: Comprobación de la introducción de datos
  rechecking_data: Comprobación de la introducción de datos
  checking_data_desc: Compruebe el valor y confírmelo pulsando ok, o rechácelo pulsando nok si es incorrecto.
  display-only-non-conformities: Mostrar sólo las columnas no conformes
  all_day: Todo el día
  category: Categoría
  cell_history: Historial de celdas
  cell_history_desc: Este campo ha sido modificado por uno o varios usuarios
  checkbox: Pendiente
  control: Control
  controls: Controles
  date: Fecha
  document: Documento
  done: Completado
  download_failed: Error
  download_failed_message: >-
    Se produjo un error al preparar su documento. | Se produjo un error al
    preparar sus documentos.
  download_finished: Descarga en curso
  download_finished_message: Recibirá un correo electrónico con el archivo en unos minutos
  download_limit_reached: Límite de descarga de 45 informes
  downloading: Descargando...
  downloading_message: >-
    ¡Espere mientras preparamos su documento para la descarga! | ¡Espere
    mientras preparamos sus documentos para la descarga!
  dynamic: Dinámico
  dynamic_target: Objetivo dinámico
  enter_value: Ingresar valor...
  execution_date: Fecha de ejecución
  filter_invalid: No válido
  filter_last_3_days: Últimos 3 días
  filter_last_7_days: Últimos
  filter_last_day: Último día
  filter_last_month: Último mes
  filter_valid: Válido
  is_valid: Estado
  job: Orden de producción
  job_abbreviation: OP
  justification: Justificación
  justify_ko_desc: >-
    Por favor, justifique el valor de la celda siguiente para continuar la
    entrada en este documento
  justify_ko_title: Justificación de no conformidad
  last_update: Última actualización el
  limit_reached: Ha alcanzado su límite, ajuste su búsqueda.
  measure: Medida
  modify_data: Modificación de un campo previamente ingresado
  modify_data_desc: >-
    Otro usuario ingresó datos en esta celda. Estos nuevos datos se actualizarán
    en las tablas de todos los participantes en el documento.
  modify_data_error: No todos los campos se completaron correctamente
  modify_data_success: Los nuevos datos se han guardado correctamente
  new_data: Nuevos datos
  no_rows_to_show: Problema al mostrar los datos
  no_steps: Este archivo está vacío
  operator: Operador
  precise_reasons: Especifique las razones de esta modificación...
  product: Producto
  raise_event: Informar un evento
  report_update_message: El formulario se ha guardado con éxito
  restore_data: Restaurar la entrada
  restore_data_desc: >-
    Estos datos se ingresaron anteriormente antes de ser reemplazados por
    entradas más recientes.
  restore_data_title: ¿Realmente quieres restaurar estos datos?
  see_alert: Ver alerta
  select_option: Seleccionar opción
  static: Estático
  status: Estado
  status_finished: Terminado
  status_history: Historial de estados
  status_in_progress: En progreso
  status_new: Nuevo
  status_being_processed: siendo procesados
  status_resolved: resuelto
  step_answer: Respuesta
  step_answer_state: Estado
  step_name: Nombre
  step_number: Paso
  target: Objetivo
  text: Texto
  update_date: Fecha de actualización
  view_report: Ver informe
  work_order: Orden de producción
  workplace: Lugar de trabajo
session:
  all_documents_not_complete: No todos tus documentos están completos
  all_documents_not_complete_desc: >-
    No puedes finalizar la operación hasta que todos los documentos obligatorios
    estén completos
  automatic_reports: Informes automáticos
  create_operation: Crear una nueva operación
  create_workorder: Crear una nueva orden de fabricación
  launch_on: Iniciar en
  of_name_requiered: Ingresa un nombre para tu orden de fabricación
  ongoing_session: Sesión en curso
  ongoing_session_message: Estás a punto de iniciar una sesión que ya comenzó
  operation_requiered: Crea al menos una nueva operación
  product_requiered: Selecciona al menos un producto para continuar
  refine_search: Refina tu búsqueda para mostrar las órdenes de fabricación relacionadas
  search_po: Buscar orden de fabricación
  select_product: Selecciona el producto
  certified: Certificado
  uncertified: Sin certificar
  to_certify: Certificar
  certification_updated_successfully: Equipo modificado con éxito
  add_team: Añadir un equipo
  select_chain_manager: Seleccionar jefe de linea
  select_chain_manager_placeholder: Seleccione el gestor
  select_team_members: Seleccionar operarios
  select_team_members_placeholder: Seleccione operadores
  line_certification_verification: Verificación de la certificación de la línea
  select_team_desc: Seleccionar a los miembros de su equipo
  update: Actualizar
  no_attachments: Los nuevos archivos adjuntos aparecerán aquí una vez que los haya añadido.
  workorder_doesnt_exist: Esta orden de fabricación no existe.
settings:
  add_option: Ingresa tu opción y presiona Enter
  additional_data: Datos adicionales
  alerts: Alertas
  audit_logs:
    action: Acción
    action_hour: Hora de la acción
    deactivate: Desactivar
    get_more: Cargar + {count} entrada | Cargar + {count} entradas
    load_more: Más entradas
    login: Inicio de sesión
    logout: Cierre de sesión
    name: Cuenta asociada
    reactivate: Reactivar
  blocker: Bloqueador
  cancel: Cancelar
  color: Color
  conception_rights: Derechos de diseño
  create_ftp_success_message: El servidor FTP se ha registrado correctamente
  create_group: Crear grupo
  create_lookup: Crear un nuevo dato
  create_product: Crear un nuevo producto
  alternative_workplaces: Puestos de trabajo alternativos
  create_role: Agregar rol
  create_setting_success_message: La configuración se ha creado correctamente
  create_status: Crear estado
  decimal_number: Número decimal
  decimal_values: Valores decimales
  default: Predeterminado
  delete: Eliminar configuración
  delete_confirmation_message: >-
    ¿Seguro que deseas eliminar la configuración? Esta acción no se puede
    deshacer.
  delete_option: Eliminar opción
  delete_option_confirmation_message: ¿Seguro que deseas eliminar esta opción? Esta acción no se puede deshacer.
  delete_setting_success_message: La configuración se ha eliminado correctamente
  disabled: Deshabilitado
  document_category: Categoría de documento
  document_status: Estado del documento
  enable_tolerance: Activar tolerancia en las medidas
  enabled: Habilitado
  ending_time: Hora de finalización
  formula: Fórmula
  frequency: Frecuencia
  get_more_document_category: Cargar + {count} categoría | Cargar + {count} categorías
  get_more_grid_header: Cargar + {count} encabezado | Cargar + {count} encabezados
  get_more_list_options: Cargar + {count} lista de selección | Cargar + {count} listas de selección
  get_more_means_of_measure: Cargar + {count} medio de medida | Cargar + {count} medios de medida
  get_more_messages: Cargar + {count} mensaje | Cargar + {count} mensajes
  get_more_step_tag: Cargar + {count} etiqueta | Cargar + {count} etiquetas
  get_more_teams: Cargar + {count} equipo | Cargar + {count} equipos
  get_more_units_of_measure: Cargar + {count} unidad de medida | Cargar + {count} unidades de medida
  get_more_workflow_models: Cargar + {count} ciclo de vida | Cargar
  grid_header: Encabezado de cuadrícula
  groups: Grupos
  input_modification: Modificación de entrada
  input_parameters: Entradas
  is_selected: Seleccionado
  justify_ko: Justificación de NC
  list_options: Opciones de lista
  list_type: Tipo de lista
  list_value: Valor de opción
  load_more_document_category: Más categorías
  load_more_grid_header: Más encabezados
  load_more_list_options: Más listas de selección
  load_more_means_of_measure: Más medios de medida
  load_more_messages: Más mensajes
  load_more_step_tag: Más etiquetas
  load_more_teams: Más equipos
  load_more_units_of_measure: Más unidades
  load_more_workflow_models: Más modelos
  lookup_exists: ya existe un registro de datos similar
  lookup_keys_error: >-
    La configuración del archivo no coincide con las claves de este tipo. Ponte
    en contacto con tu jefe de operaciones.
  lookup_tables: Tabla de asignación
  mandatory_column: Este campo es obligatorio
  mandatory_fields: Por favor asegúrese de completar todos los campos obligatorios
  mandatory_finish: Entrada obligatoria
  maximum_inactivity_time: Tiempo máximo de inactividad antes de desconectar
  enable_incativity_logout: Cierre de sesión tras inactividad
  means_of_measure: Medio de medida
  measure_unit: Unidad de medida
  media_library: Habilitado
  members: Miembros
  message: Contenido
  name: Nombre
  new_status: Estado sin nombre
  new_user: Nuevo usuario
  no_user_found: No se encontraron usuarios
  number_of_days_of_validity: Número de días de validez de la contraseña actual
  number_of_decimals: Número de decimales
  operator_session: Sesión
  order: Orden
  organization: Organización
  parameter_name: Nombre
  parameter_value: Valor
  password_expiration: Caducidad de la contraseña
  precise_decimal_number: Especificar el número decimal
  product: Producto
  products:
    export: Exportar lista de productos en formato CSV
    import: Importar un archivo CSV
  profile: Perfil
  reasons: Justificación de modificación
  repetition: Repetición
  repetition_type: Tipo de repetición
  report_status: Estado del informe
  reset_automatically: Reinicio automático
  roles: Roles y permisos
  roles_access: Accesible por
  roles_cannot_delete_description: >-
    {amount} usuario(s) tienen este rol. Asigna un rol diferente o elimínalo
    primero.
  roles_cannot_delete_title: No se puede eliminar el rol
  roles_change-status: Cambiar estado
  roles_confirm_delete_description: >-
    Al confirmar, el rol se eliminará definitivamente. Esta acción no se puede
    deshacer.
  roles_confirm_delete_title: ¿Confirmar eliminación?
  roles_create: Creado
  roles_delete: Archivar
  roles_delete_role: Eliminar
  roles_edit_name: Editar nombre
  roles_new: Nuevo rol
  roles_modify: Modificar
  roles_read: Consultar
  roles_ressource: Recurso
  roles_write: Modificar
  same_as_tolerance: Igual que la tolerancia
  sampling: Muestreo
  save_status: Guardar estado
  search_user_placeholder: Buscar y agregar nuevos miembros
  settings_error: Se produjo un problema al conectarse al servidor
  starting_time: Hora de inicio
  status_color: Color
  status_icon: Icono
  status_name: Nombre
  status_saved: El estado se ha guardado
  step_tag: Etiqueta
  team: Equipo
  team_name: Nombre del equipo
  theTeam: El equipo
  tolerance: Tolerancia
  type: Tipo
  unit: Unidad
  units_of_measure: Unidades de medida
  update_group: Modificar grupo
  update_password_message_description: El usuario deberá iniciar sesión nuevamente con la nueva contraseña
  update_password_message_title: ¿Confirmar cambio de contraseña?
  update_setting_error_message: Se ha producido un error. La configuración no se ha modificado.
  update_setting_success_message: La configuración se ha modificado correctamente
  update_setting_tolerance_error_message: No puedes modificar la tolerancia
  users: Usuarios
  users_and_access: Cuentas y acceso
  value: Valor
  value_exists: Este nombre ya está en uso
  value_tolerance: Valor de tolerancia
  workflow: Ciclo de vida
  workplaces:
    export: Exportar lista de puestos de trabajo en formato CSV
    import: Importar un archivo CSV
sidebar:
  documents: Fichas
  handle_documents: Gestionar mis fichas
  incidents: Incidentes
  manage_production: Gestionar mi producción
  media_library: Mediateca
  performance_analysis: Analizar mi rendimiento
  performance_analysis_production: Producción
  performance_analysis_quality: Calidad
  production_monitoring: Seguimiento de producción
  production_order_monitoring: Seguimiento de las órdenes de fabricación
  incident_form: Formulario de incidente
simulation:
  empty_simulation: Actualmente no tienes simulaciones. Puedes comenzar a crear una.
  new_simulation: Nueva simulación
sso:
  authentication_error: >-
    Se encontró un error al conectarse al proveedor de sso; consulte con su
    administrador
status:
  created: El estado se ha creado correctamente
  deleted: El estado se ha eliminado correctamente
  status: Estado
  statuses: Estados
  updated: El estado se ha actualizado correctamente
messages:
  no_data: datos no disponibles
step:
  absolute_value: Valor absoluto
  add_condition: Agregar una condición
  add_file: Agregar un archivo
  add_one_or_multiple_files: Añadir uno o varios archivos
  answer_nok: Nok
  answer_ok: Ok
  boolean: OK / NOK
  checkbox: Casilla de verificación
  create_answer: Crear respuesta
  customized_answer: Respuesta personalizada
  delete_step_description: Este bloque no puede borrarse porque depende de un bloque de cálculo.
  delete_step_title: Imposible borrar bloque
  description_placeholder: Ingresar una descripción
  done: Hecho
  file: Archivo
  file_upload_issue: Se ha encontrado un problema al cargar el archivo
  link: Enlace
  list: Selección
  mandatory: Obligatorio
  double_check: Doble control
  margin: Margen
  means: Medio de medida
  measure: Medida
  measure_max: Máximo
  measure_min: Mínimo
  measure_target: Objetivo
  measure_tolerance: +/-
  measure_unit: Unidad
  multiple_selections: Selección múltiple
  name_placeholder: Ingresar un nombre
  napossible: No aplicable
  no_step_available: No hay bloques disponibles
  percentage: Porcentaje
  select_condition_item_in_list: Seleccionar una respuesta...
  select_condition_step_in_list: Seleccionar bloques a mostrar...
  set_value: Valor definido
  take: Veces
  uncertainty: Incertidumbre
  preview_calculation: Cálculo de vista previa
  create_calculation: Crear un cálculo
  incorrect_calcul: Error de cálculo
  delete_all: Eliminar todos
  requested_entries: Entradas solicitadas
  calcul_preview: Vista previa del cálculo
  calculation_step: Bloque de cálculo
  block: Bloque
  mathematical_operators: Operadores matemáticos
  create_calcul_placeholder: >-
    Cree su cálculo agregando las entradas requeridas y los operadores
    matemáticos.
  fill_calcul_btn: hacer un calculo
  fill_calcul_title: Complete los siguientes campos para obtener el resultado del cálculo
template:
  archive: Archivar la plantilla
  archive_template: Archivar
  are_you_sure_edit: >-
    La modificación de esta plantilla afectará todos los documentos asociados,
    ¿seguro que deseas continuar?
  create: Nueva plantilla
  delete_confirmation_message: ¿Seguro que quieres archivar esta plantilla?
  get_more: Cargar + { count } plantillas
  load_more: Más plantillas
  modify: Modificar plantilla
  new_template: Nueva plantilla
  publish_template: Publicar
  reference: Referencia
  search: Buscar plantilla
  template_delete_used_reference: 'Archivar la plantilla es irreversible y afectará los siguientes documentos:'
  template_name: Nombre de la plantilla
  template_update_documents_error_message: Algunos documentos no se actualizaron correctamente, inténtalo de nuevo.
  template_update_error_message: Se ha producido un error al guardar la plantilla
  template_update_message: La plantilla se ha modificado correctamente
  template_used_reference: >-
    Cualquier modificación en la plantilla es irreversible y afectará los
    siguientes documentos:
  unlink_template: Desvincular
trigger:
  day: Día
  production_order: Orden de fabricación
  team: Equipo
user:
  active: Activo
  automatic_deactivation: Desactivación automática
  by: por
  client: Cliente
  confirmation_description_activate_user: El usuario tendrá acceso nuevamente a JUNO.
  confirmation_description_desactivate_user: >-
    El usuario ya no podrá acceder a JUNO una vez que su cuenta esté
    desactivada.
  confirmation_title_activate_user: ¿Estás seguro de querer reactivar la cuenta de {name}?
  confirmation_title_desactivate_user: ¿Seguro que quieres desactivar la cuenta de {name}?
  deactivated_successfully: Esta cuenta ha sido desactivada
  desactivate: Desactivar
  disable_account: Desactivar la cuenta
  disabled: Desactivado
  disabled_at: Desactivado el
  disabled_automatically_at: Desactivado automáticamente el
  edit_information: Editar información
  email: Correo electrónico
  language: Idioma
  modify_password: Modificar la contraseña
  nfc_authentication_status: Estado de autenticación NFC
  nfc_code: Código NFC
  nfc_status: Estado NFC
  nfc_text_validity: Prueba de validez de NFC
  password: Contraseña
  personal_information: Información personal
  reactivate: Reactivar
  reactivate_account: Reactivar la cuenta
  reactivated_successfully: Esta cuenta ha sido reactivada
  role: Rol
  role_attribution_mandatory: La atribución de un rol es obligatoria
  site: Sitio
  status: Estado
  temporary: Temporal
  temporary_account: Cuenta temporal
  unknown_creator: Creador desconocido
  updated: El usuario ha sido actualizado
  site_attribution_mandatory: ''
  user_certified: Certificación de usuario
  operator_certified: Operario Certificado
  chain_manager_certified: Jefe de Linea certificado
users:
  get_more: Cargar + { count } usuario | Cargar + { count } usuarios
  load_more: Más usuarios
  readonly_mode: Modo de solo lectura
validate_nc: Validar las CN
validations:
  document_category: La categoría no puede estar vacía
  document_description: El campo de descripción es obligatorio
  document_name: El campo de nombre es obligatorio
  minimum_characters: Ingresa al menos {length} caracteres
views:
  add: Crear vista
  delete: Borrar vista
  delete_confirmation_message: ¿Estás seguro de querer eliminar esta vista?
  delete_modal_title: Eliminar vista
  description: Descripción de la vista
  error_create: Se produjo un error al crear la vista
  error_delete: Se produjo un error al eliminar la vista
  error_empty_title: El título no puede estar vacío
  error_update: Se produjo un error al guardar la vista
  filters: Filtros activos
  get_more_reports: Cargar + { count } informe | Cargar + { count } informes
  load_more_reports: Más informes
  name: Nombre de la vista
  new_vue_template_description: Ingresa una descripción aquí
  new_vue_template_title: Nueva vista
  no_filters_selected: Ningún filtro seleccionado
  no_group: Ninguno
  save: Guardar vista
  save_view: Guardar
  saved_views: Vistas guardadas
  title: Supervisión
  view_all: Ver todo
  view_created: Se ha creado una nueva vista con éxito
  view_delete: La vista se ha eliminado con éxito
  view_updated: La vista se ha modificado con éxito
welcome_url:
  analytics: Análisis
  conception: Diseño
  designer_space: Espacio de supervisión
  library: Biblioteca
  operation: Operación
  operator_free: Todos los puestos de trabajo
  operator_selection: Selección de puesto de trabajo
  operator_space: Espacio de producción
  select_option: Seleccionar...
  space: Espacio
  supervision: Supervisión
  welcome_page: Página de bienvenida
workflows:
  add: Agregar paso
  add_description: Agregar descripción
  associated_permissions: Permisos asociados
  associated_status: Estado asociado
  built_in: Ciclos de vida predefinidos
  create: Crear ciclo de vida
  created: El ciclo de vida se creó
  customizable: Ciclos de vida personalizables
  default: Ciclo de vida predefinido
  default_alert_step_1: Nueva alerta
  default_alert_step_2: Alerta en proceso
  default_alert_step_3: Alerta gestionada
  incident: Incidente
  default_document_step_1: Documento en proceso de edición
  default_document_step_2: Documento publicado
  default_of_step_1: Nueva orden de fabricación
  default_of_step_2: Orden de fabricación en proceso
  default_of_step_3: Orden de fabricación completada
  default_operations_step_1: Nueva operación
  default_operations_step_2: Operación en proceso
  default_operations_step_3: Completar operación
  default_report_step_1: Nuevo informe
  default_report_step_2: Informe en proceso
  default_report_step_3: Informe completado
  description: Descripción
  error: Se produjo un error al crear/actualizar el ciclo de vida
  error_inputs: Completa los campos obligatorios para crear el nuevo paso
  name: Nombre del ciclo de vida
  new_workflow_title: Nuevo ciclo de vida
  no_workflow: Sin ciclo de vida
  not_found: Ningún ciclo de vida encontrado
  not_found_description: >-
    Crea un nuevo ciclo de vida haciendo clic en el botón en la esquina superior
    derecha de la pantalla
  save: Guardar ciclo de vida
  search_status: Buscar estado...
  select_option: Seleccionar estado
  select_workflow: Seleccionar ciclo de vida
  select_workflow_type: Selecciona un tipo de ciclo de vida para crear uno nuevo
  templates: Plantillas
  title: Ciclo de vida
  type: Tipo
  type_alerts: Alerta
  type_documents: Documento
  type_of: Orden de fabricación
  type_operations: Operación
  type_reports: Informe
  type_select: Seleccionar tipo
  unnamed: Ciclo de vida sin nombre
  updated: El ciclo de vida se actualizó con éxito
workorder:
  complete: Completar la operación
  confirm_modal_description: >-
    Estás a punto de completar la operación, ¿has completado todos tus
    documentos?
  confirm_modal_title: Completar y cerrar la operación en curso
incident_form:
  new: Nuevo Formulario
  new_step: Neuer Schritt
  title: Formulario de incidente
  my_forms: Meine Formulare
  remove_branch: Sind Sie sicher, dass Sie diesen Zweig löschen möchten?
  remove_branch_description: Diese Aktion kann nicht rückgängig gemacht werden. Alle Werte, die mit diesem Zweig verbunden sind, gehen verloren.
simulator:
  generate: Generar
  derivation: Derivación
  derivation_data: Datos de derivación
  available_only_for_pilot_mode: Disponible solo en modo piloto
projects:
  create_new: Nuevo proyecto
  all_projects: Todos los proyectos
  assignees: Asignados
  dates: Fechas
  deadline: Fecha límite
  priority:
    title: Prioridad
    low: Baja
    medium: Media
    high: Alta
    tbd: Por hacer
  progress: Progreso
  budget: Presupuesto
  title: Proyecto
  information: Información del proyecto
  localisation: Localización
  new: Nuevo proyecto
  assignees_placeholder: Ningún asignado
  name_placeholder: Da un nombre al proyecto
  budget_placeholder: Da un costo al proyecto
  description_placeholder: ¿De qué trata este proyecto?
  deadline_placeholder: Sin fecha
  priority_placeholder: ¿Cuál es la prioridad de este proyecto?
  localisation_placeholder: ¿Dónde se encuentra este proyecto?
  filters:
    day_hour: Día/Hora
    week_day: Semana/Día
    month_day: Mes/Día
    year_month: Año/Mes
    year: Año
  actions:
    all_actions: Todas las acciones
    activity: Actividad
    create_new: Nueva acción
    deliverable: Entregable
    add_delivery_description_placeholder: Introducir una descripción...
    add_delivery_file: Adjuntar un archivo
    activity_all: Todas
    activity_comment: Comentarios
    activity_status: Historial de estados
    add_new: Añadir una acción
    project: Proyecto
    title: Acciones del proyecto
    history:
      assigned: asignó la acción a
      commented: comentó
      created: creó la acción
      updated: cambió el estado de la acción
    project_placeholder: Añadir la acción a proyectos
    category_placeholder: Sin categoría
    name_placeholder: Da un nombre a la acción
    no_description: No se proporcionó descripción.
    description_placeholder: ¿De qué trata esta acción?
